import { template as template_a12beeb725e14e3fb7752d9615ef75cd } from "@ember/template-compiler";
const FKLabel = template_a12beeb725e14e3fb7752d9615ef75cd(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
