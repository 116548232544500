import { template as template_d3e34be98f664adaa5f03ed415c8fa57 } from "@ember/template-compiler";
const FKControlMenuContainer = template_d3e34be98f664adaa5f03ed415c8fa57(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
